import { createRouter, createWebHistory } from 'vue-router';
import UserTracker from './components/UserTracker.vue'; // Make sure the path to your component is correct

const routes = [
  {
    path: '/',
    name: 'UserTracker',
    component: UserTracker,
  },
  // You can add more routes here if needed
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
