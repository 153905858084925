import { defineComponent } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faSearch, faTrash, faSync } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

export default defineComponent({
  components: {
    FontAwesomeIcon,
  },
  
  data() {
    return {
      searchTerm: "",
      userData: null,
      searches: JSON.parse(localStorage.getItem("userTrackerSearches")) || [],
      error: "",
      faSearch,
      faTrash,
      faSync,
      friends: [],
      displayedFriends: [],
      friendsPage: 1,
      friendsPerPage: 10,
      hasMoreFriends: true,
    };
  },
  created() {
    this.fetchUserData("Caneeeee", false);
  },
  watch: {
    searches: {
      handler(newSearches) {
        localStorage.setItem(
          "userTrackerSearches",
          JSON.stringify(newSearches)
        );
      },
      deep: true,
    },
  },
  methods: {
    async fetchUserData(username, saveToHistory = true) {
      try {
        const response = await axios.get(
          `https://www.habbo.de/api/public/users?name=${username}`
        );
        this.userData = response.data;
        this.error = "";
        if (
          saveToHistory &&
          !this.searches.some((search) => search.name === response.data.name)
        ) {
          this.searches.unshift(response.data);
        }
        this.fetchFriends(response.data.uniqueId);
      } catch (err) {
        this.error =
          "Habbo nicht gefunden oder der Habbo hat ein geschlossenes Profil";
        this.userData = null;
      }
    },
    async fetchFriends(uniqueId) {
      try {
        const response = await axios.get(
          `https://www.habbo.com/api/public/users/${uniqueId}/friends`
        );
        this.friends = response.data;
        this.displayedFriends = this.friends.slice(0, this.friendsPerPage);
        this.hasMoreFriends = this.friends.length > this.friendsPerPage;
      } catch (err) {
        this.error = "Fehler beim Laden der Freunde";
      }
    },
    loadMoreFriends() {
      const start = this.friendsPerPage * this.friendsPage;
      const end = start + this.friendsPerPage;

      this.displayedFriends = [
        ...this.displayedFriends,
        ...this.friends.slice(start, end),
      ];

      this.friendsPage++;
      this.hasMoreFriends = end < this.friends.length;
    },
    handleSearch() {
      if (this.searchTerm) {
        this.fetchUserData(this.searchTerm);
      }
    },
    deleteAllSearches() {
      this.searches = [];
    },
    async refreshOnlineStatus() {
      const updatedSearches = await Promise.all(
        this.searches.map(async (search) => {
          try {
            const response = await axios.get(
              `https://www.habbo.de/api/public/users?name=${search.name}`
            );
            return response.data;
          } catch (err) {
            return search;
          }
        })
      );
      this.searches = updatedSearches;
    },
    formatLastAccess(lastAccessTime) {
      const now = new Date();
      const lastAccess = new Date(lastAccessTime);
      const diffInSeconds = Math.floor((now - lastAccess) / 1000);

      const pluralize = (count, singular, plural) =>
        count === 1 ? singular : plural;

      if (diffInSeconds < 60)
        return `${diffInSeconds} ${pluralize(
          diffInSeconds,
          "Sekunde",
          "Sekunden"
        )} her`;
      if (diffInSeconds < 3600)
        return `${Math.floor(diffInSeconds / 60)} ${pluralize(
          Math.floor(diffInSeconds / 60),
          "Minute",
          "Minuten"
        )} her`;
      if (diffInSeconds < 86400)
        return `${Math.floor(diffInSeconds / 3600)} ${pluralize(
          Math.floor(diffInSeconds / 3600),
          "Stunde",
          "Stunden"
        )} her`;
      if (diffInSeconds < 2592000)
        return `${Math.floor(diffInSeconds / 86400)} ${pluralize(
          Math.floor(diffInSeconds / 86400),
          "Tag",
          "Tage"
        )} her`;

      return lastAccess.toLocaleDateString("de-DE");
    },

    formatExactTime(timestamp) {
      if (!timestamp) return "";
      const date = new Date(timestamp);
      return date.toLocaleString();
    },

    handleHistoryItemClick(username) {
      this.searchTerm = username;
      this.handleSearch();
    },

    handleFriendItemClick(username) {
      this.searchTerm = username;
      this.handleSearch();
    },
  },
});
