import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // if using Vue Router
import store from './store'; // ensure this path is correct


createApp(App)
  .use(router)
  .use(store)
  .mount('#app');
