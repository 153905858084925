<template>
  <div class="user-tracker">
    <h1>Habbo Profil Tracker</h1>
    <form @submit.prevent="handleSearch" class="search-form">
      <input
        v-model="searchTerm"
        type="text"
        placeholder="Habboname eingeben"
      />
      <button type="submit"><FontAwesomeIcon :icon="faSearch" /> Suchen</button>
    </form>

    <p v-if="error" class="error">{{ error }}</p>

    <div class="main-content">
      <div class="user-card-container">
       <div v-if="userData && !error" class="user-card">
          <img
            :src="`https://www.habbo.de/habbo-imaging/avatarimage?size=l&figure=${userData.figureString}&gesture=std&action=std&direction=2&head_direction=2&headonly=0`"
            :alt="userData.name"
            class="avatar"
          />
          <h2>{{ userData.name }}</h2>
          <p>Motto: <div class="motto">{{ userData.motto || "Kein Motto" }}</div></p>
          <p>Online: {{ userData.online ? "Ja" : "Nein" }}</p>
          <p>
            Letzter Login:
            <span>{{ formatLastAccess(userData.lastAccessTime) }}</span> -
            <small
              >Exakte Zeit:
              {{ formatExactTime(userData.lastAccessTime) }}</small
            >
          </p>
          <p>
            Mitglied seit:
            {{ new Date(userData.memberSince).toLocaleDateString() }}
          </p>
          <p>Profil Sichtbar: {{ userData.profileVisible ? "Ja" : "Nein" }}</p>

          <div class="badges">
            <h2>Angezogene Badges:</h2>
            <ul class="badge-list">
              <li
                v-for="(badge, index) in userData.selectedBadges"
                :key="index"
                class="badge-item"
              >
                <div class="badge-icon-container" :title="badge.name">
                  <img
                    :src="`https://images.habbo.com/c_images/album1584/${badge.code}.png`"
                    :alt="badge.name"
                    class="badge-icon"
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="search-history-container">
        <h2>Suchverlauf</h2>
        <div class="history-actions">
          <button @click="deleteAllSearches" class="delete-all">
            <FontAwesomeIcon :icon="faTrash" /> Löschen
          </button>
          <button @click="refreshOnlineStatus" class="refresh-all">
            <FontAwesomeIcon :icon="faSync" /> Online Status aktuallisieren
          </button>
        </div>

        <div class="history-list-wrapper">
          <div class="history-list">
            <div
              v-for="(search, index) in searches"
              :key="index"
              class="history-item"
              @click="handleHistoryItemClick(search.name)"
            >
              <img
                :src="`https://www.habbo.de/habbo-imaging/avatarimage?size=l&figure=${search.figureString}&gesture=std&action=std&direction=2&head_direction=2&headonly=0`"
                :alt="search.name"
                class="avatar-small"
              />
              <span>{{ search.name }}</span>
              <span :class="search.online ? 'online' : 'offline'">
                {{ search.online ? "Online" : "Offline" }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="friends.length" class="friends-section">
      <h2>Freunde</h2>
      <div class="friends-list">
        <div
          v-for="(friend, index) in displayedFriends"
          :key="friend.uniqueId"
          class="friend-item"
          @click="handleFriendItemClick(friend.name)"
        >
          <img
            :src="`https://www.habbo.de/habbo-imaging/avatarimage?size=l&figure=${friend.figureString}&gesture=std&action=std&direction=2&head_direction=2&headonly=0`"
            :alt="friend.name"
            class="avatar-small"
          />
          <span>{{ friend.name }}</span
          ><br />
          <span><div class="motto">{{ friend.motto || "Kein Motto" }}</div></span>

          <!-- Online/Offline badge in the top-right corner -->
          <span :class="friend.online ? 'online-badge' : 'offline-badge'">
            {{ friend.online ? "Online" : "Offline" }}
          </span>
        </div>
      </div>

      <button v-if="hasMoreFriends" @click="loadMoreFriends" class="load-more">
        <FontAwesomeIcon :icon="faSync" /> Mehr Laden
      </button>
    </div>
  </div>
</template>

<script>
import logic from "./UserTracker.js";

export default logic;
</script>

<style scoped>
@import "@/assets/UserTracker.css";
</style>
