<template>
  <div id="app">
    <UserTracker />
  </div>
</template>

<script>
import UserTracker from "./components/UserTracker.vue";

export default {
  components: {
    UserTracker,
  },
};
</script>
